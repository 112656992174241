<template>
    <div class="leaderboard-data">
        <div class="table">
            <!-- <div class="table__head">
                <div class="table__row">
                    <div
                        class="table__row--rankcells"
                        :data-width="isMobileScreen ? 10 : 16"
                    >
                        RANK
                    </div>
                    <div
                        class="table__row--cells name-cells"
                        :data-width="isMobileScreen ? 7 : 28"
                    >
                        NAME
                    </div>
                    <div class="table__row--cells points-cells" data-width="16">
                        POINTS
                    </div>
                </div>
            </div> -->
            <div v-if="users && users.length" class="table__body">
                <div v-for="(data, i) in users" :key="i" class="table__row">
                    <div
                        class="table__row--rankcells"
                        :data-width="10"
                        data-label=" Rank"
                    >
                        {{ data.rank }}
                    </div>
                    <!-- :data-width="isMobileScreen ? 7 : 28" -->
                    <div class="table__row--cells name-cells" data-label="NAME">
                        <div>
                            <p>{{ data.display_name }}</p>
                            <span> {{ data.country }}</span>
                            <span class="level">{{
                                data.level && data.level.name
                                    ? data.level.name
                                    : ''
                            }}</span>
                        </div>
                    </div>
                    <div
                        class="table__row--cells points-cells"
                        data-width="10"
                        data-label="Points"
                    >
                        <div class="position-relative">
                            <b-dropdown
                                id="dropdown-1"
                                :text="`${data.score} `"
                                :offset="$i18n.locale == 'en' ? -95 : 95"
                            >
                                <b-dropdown-item
                                    >Ideas Submitted:<span>{{
                                        data.my_submitted_idea
                                    }}</span></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Resubmitted:<span>{{
                                        data.my_resubmitted_idea
                                    }}</span></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Approved:<span>{{
                                        data.my_approved_idea
                                    }}</span></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Dismissed:<span>{{
                                        data.my_dismissed_idea
                                    }}</span></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Planned:<span>{{
                                        data.my_planned_idea
                                    }}</span></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Implemented:<span>{{
                                        data.my_implemented_idea
                                    }}</span></b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else><h1>No Data Available</h1></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            default: () => [],
        },
        isMobileScreen: {
            type: Boolean,
            default: false,
        },
    },
    // data() {
    //     return {
    //         individualData: [],
    //     }
    // },
    // mounted() {
    // if (this.users && this.users.length > 3 && !this.isMobileScreen) {
    //     this.individualData = this.users.slice(3)
    // } else if (this.users && this.users.length && this.isMobileScreen) {
    //     this.individualData = this.users
    // } else this.individualData = []
    // },
    // watch: {
    //     users() {
    // if (this.users && this.users.length > 3 && !this.isMobileScreen) {
    //     this.individualData = this.users.slice(3)
    // } else if (this.users && this.users.length && this.isMobileScreen) {
    //     this.individualData = this.users
    // } else this.individualData = []
    //     },
    // },
}
</script>

<style lang="scss" scoped>
.leaderboard-data {
    display: flex;
    justify-content: center;
    h1 {
        margin-top: 150px;
        text-align: center;
    }
    /deep/ {
        .table {
            max-width: 100%;
            .dropdown-toggle {
                font-size: 1.5rem;
                padding-right: 21px;
                min-width: auto;
                width: auto;
                &:after {
                    right: 0;
                    border: 0;
                }
                &:hover {
                    color: var(--primary);
                }
            }
            .dropdown-menu {
                width: 180px;
            }
            // &__head {
            //     .table__row {
            //         border: none !important;
            //         padding: rem(20px) 0 0 0 !important;
            //         &--cells {
            //             font-size: rem(16px);
            //             color: #727272;
            //             font-weight: 600;
            //             &.points-cells {
            //                 text-align: right;
            //             }
            //             &.name-cells {
            //                 @media screen and (max-width: 450px) {
            //                     padding: rem(16px) rem(16px) rem(16px) rem(0px) !important;
            //                 }
            //             }
            //         }
            //         &--rankcells {
            //             font-size: rem(16px);
            //             color: #727272;
            //             font-weight: 600;
            //             text-align: center;
            //         }
            //     }
            //     @media screen and (max-width: 991px) {
            //         display: flex !important;
            //     }
            // }
            &__row {
                &--cells {
                    display: table-cell;
                    font-size: rem(16px);
                    color: #727272;
                    font-weight: 600;
                    vertical-align: middle;
                    background-color: transparent;
                    text-align: left;
                    line-height: 1.2;
                    padding: rem(16px) rem(16px) rem(16px) rem(16px) !important;
                    font-size: 15px;

                    @media screen and (max-width: 991px) {
                        font-size: 14px;
                    }
                    // img {
                    //     max-width: 19%;
                    // }
                    &.points-cells {
                        text-align: right;
                        font-size: 1.4rem;
                    }
                    &.name-cells {
                        width: inherit;
                        p {
                            line-height: 1.3;
                            font-weight: 500;
                            font-size: 1.2rem;
                            color: black;
                            margin: 3px 0;
                            @media screen and (max-width: 991px) {
                                font-size: 14px;
                            }
                        }
                        span {
                            line-height: 1.3;
                            display: block;
                            font-size: 1.1rem;
                            font-weight: 500;
                            color: #727272;
                            &.level {
                                color: var(--primary);
                            }
                        }
                    }
                }
                &--rankcells {
                    display: table-cell;
                    padding: rem(20px) rem(0px);
                    font-size: 1.3rem;
                    color: #727272;
                    font-weight: 600;
                    vertical-align: middle;
                    background-color: transparent;
                    text-align: center;
                    line-height: 1.2;
                }
            }
            &__body {
                padding-top: 1rem;
                .table {
                    &__row {
                        border: 1px solid #e0e0e0;
                        margin-bottom: 8px;
                        &:hover {
                            background: transparent !important;
                            box-shadow: none !important;
                            .table {
                                &__row {
                                    &--cell {
                                        color: #000 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                &__row {
                    &--cell {
                        img {
                            max-height: 40px;
                            max-width: 88px;
                        }
                    }
                }
            }
            @media screen and (max-width: 991px) {
                margin-top: rem(30px);
            }
            @media screen and (max-width: 575px) {
                &__row {
                    &--cell {
                        img {
                            max-height: 27px;
                            max-width: 40px;
                        }
                    }
                }
            }
        }
    }
}
.ar {
    .leaderboard-data {
        /deep/ {
            .table {
                .dropdown-toggle {
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 60px;
                    &:after {
                        left: 0px;
                        right: 40px;
                    }
                }
                &__row {
                    &--cells {
                        // padding: rem(16px) rem(40px) rem(16px) rem(16px) !important;
                        text-align: right;
                        &.points-cells {
                            text-align: left;
                            @media screen and (max-width: 490px) {
                                padding: rem(16px) rem(16px) rem(16px) rem(16px) !important;
                            }
                        }
                    }
                    // &--rankcells {
                    //     @media screen and (max-width: 490px) {
                    //         padding: rem(16px) rem(16px) rem(16px) rem(16px) !important;
                    //     }
                    // }
                }
            }
        }
    }
}
</style>

<template>
    <div class="leaderboard-data">
        <div class="table">
            <!-- <div class="table__head">
                <div class="table__row">
                    <div class="table__row--rankcells" data-width="16">
                        RANK
                    </div>
                    <div class="table__row--cells " data-width="28">OPCO</div>
                    <div class="table__row--cells points-cells" data-width="16">
                        POINTS
                    </div>
                </div>
            </div> -->
            <div v-if="opcoData && opcoData.length" class="table__body">
                <div
                    v-for="(opco, index) in opcoData"
                    :key="index"
                    class="table__row"
                >
                    <div
                        class="table__row--rankcells"
                        data-width="16"
                        data-label=" Rank"
                    >
                        {{ isMobileScreen ? index + 1 : index + 4 }}
                    </div>
                    <div
                        class="table__row--cells opco-image"
                        data-width="28"
                        data-label="opco"
                    >
                        <div class="opco-image-name">
                            <div class="image">
                                <img
                                    :src="imageName(opco.country)"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <p class="opco">{{ opco.country }}</p>
                        </div>
                    </div>
                    <div
                        class="table__row--cells points-cells"
                        data-width="16"
                        data-label="Points"
                    >
                        <div class="position-relative">
                            <b-dropdown
                                :text="`${opco.score_sum}`"
                                :offset="$i18n.locale == 'en' ? -95 : 0"
                            >
                                <b-dropdown-item
                                    >Ideas Submitted:<span>
                                        {{ opco.submitted }}</span
                                    ></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Resubmitted:<span>
                                        {{ opco.resubmitted }}</span
                                    ></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Approved:<span>
                                        {{ opco.approved }}</span
                                    ></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Dismissed:<span>
                                        {{ opco.dismissed }}</span
                                    ></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Planned:<span>
                                        {{ opco.planned }}</span
                                    ></b-dropdown-item
                                >
                                <b-dropdown-item
                                    >Ideas Implemented:<span>
                                        {{ opco.implemented }}</span
                                    ></b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UsersHelper } from '@/common/crud-helpers/users'
import { mapGetters } from 'vuex'

export default {
    props: {
        isMobileScreen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            opcoData: [],
        }
    },
    methods: {
        imageName(val) {
            if (val === 'ZainTech' || val === 'Zain Group') val = 'Kuwait'
            if (val === 'KSA') val = 'Saudi Arabia'
            if (val === 'Zain Cash') val = 'Jordan'
            const imageName = val.toLowerCase().replace(' ', '-')
            const image = require(`../../../../assets/images/leaderboard/country-flags/${imageName}.png`)
            return image
        },
    },
    created() {
        UsersHelper.getOpcoRanking()
        if (
            this.opcoRanking &&
            this.opcoRanking.result &&
            this.opcoRanking.result.length > 3 &&
            !this.isMobileScreen
        ) {
            this.opcoData = this.opcoRanking.result.slice(3)
        } else if (
            this.opcoRanking &&
            this.opcoRanking.result &&
            this.isMobileScreen
        ) {
            this.opcoData = this.opcoRanking.result
        } else this.opcoData = []
        this.opcoData = this.opcoData.filter(opco => opco.score_sum !== 0)
    },
    computed: { ...mapGetters(['opcoRanking']) },
    watch: {
        opcoRanking() {
            if (
                this.opcoRanking &&
                this.opcoRanking.result &&
                this.opcoRanking.result.length > 3 &&
                !this.isMobileScreen
            ) {
                this.opcoData = this.opcoRanking.result.slice(3)
            } else if (
                this.opcoRanking &&
                this.opcoRanking.result &&
                this.isMobileScreen
            ) {
                this.opcoData = this.opcoRanking.result
            } else this.opcoData = []
            this.opcoData = this.opcoData.filter(opco => opco.score_sum !== 0)
        },
    },
}
</script>

<style lang="scss" scoped>
.leaderboard-data {
    display: flex;
    justify-content: center;
    /deep/ {
        .table {
            max-width: 500px;
            .dropdown-toggle {
                font-size: 1.5rem;
                padding-right: 21px;
                min-width: auto;
                width: auto;
                &:after {
                    right: 0;
                    border: 0;
                }
                &:hover {
                    color: var(--primary);
                }
            }
            .dropdown-menu {
                width: 180px;
            }
            // &__head {
            //     .table__row {
            //         border: none !important;
            //         padding: rem(20px) 0 0 0 !important;
            //         &--cells {
            //             font-size: rem(16px);
            //             color: #727272;
            //             font-weight: 600;
            //             &.points-cells {
            //                 text-align: right;
            //                 padding: rem(16px) rem(16px) rem(16px) rem(0px);
            //             }
            //         }
            //         &--rankcells {
            //             font-size: rem(16px);
            //             color: #727272;
            //             font-weight: 600;
            //             text-align: center;
            //             padding: 0 !important;
            //         }
            //     }
            //     @media screen and (max-width: 991px) {
            //         display: flex !important;
            //     }
            // }
            &__row {
                &--cells {
                    display: table-cell;
                    padding: rem(20px) rem(24px);
                    font-size: rem(16px);
                    color: #727272;
                    font-weight: 600;
                    vertical-align: middle;
                    background-color: transparent;
                    line-height: 1.2;
                    text-align: left;
                    padding: 0 !important;
                    &.points-cells {
                        padding: rem(16px) rem(16px) rem(16px) rem(20px) !important;
                        text-align: right;
                        @media screen and (max-width: 991px) {
                            padding: rem(16px) rem(16px) rem(16px) rem(32px) !important;
                        }
                        @media screen and (max-width: 374px) {
                            padding: rem(16px) rem(16px) rem(16px) rem(0px) !important;
                        }
                    }
                    p {
                        line-height: 1;
                        font-weight: 500;
                        font-size: 14px;
                        color: #727272;
                        margin: 3px 0;
                        width: 90px;
                        @media screen and (max-width: 450px) {
                            width: 20px;
                        }
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--primary);
                    }
                    &.opco-image {
                        padding: 0px !important;
                        .opco-image-name {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            p {
                                @media screen and (max-width: 450px) {
                                    width: 90px;
                                }
                            }
                            .image {
                                border: 1px solid var(--dark);
                                width: rem(48px);
                                height: rem(48px);
                                margin-right: rem(20px);
                                border-radius: 100%;
                                img {
                                    border-radius: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                &--rankcells {
                    display: table-cell;
                    padding: rem(20px) rem(24px);
                    font-size: 1.5rem;
                    color: #727272;
                    font-weight: 600;
                    vertical-align: middle;
                    background-color: transparent;
                    text-align: center;
                    line-height: 1.2;
                    padding: 0 !important;
                }
            }
            &__body {
                .table {
                    &__row {
                        border: 1px solid #e0e0e0;
                        margin-bottom: 8px;
                        &:hover {
                            background: transparent !important;
                            box-shadow: none !important;
                            .table {
                                &__row {
                                    &--cells {
                                        color: #000 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ar {
    .leaderboard-data {
        /deep/ {
            .table {
                .dropdown-toggle {
                    padding-left: 20px;
                    &:after {
                        left: 0px;
                        right: 60px;
                        @media screen and (max-width: 991px) {
                            right: 55px;
                        }
                    }
                }
                &__row {
                    &--cells {
                        padding: 0px !important;
                        text-align: right;
                        &.points-cells {
                            text-align: left;
                            padding: rem(16px) rem(16px) rem(16px) rem(20px) !important;
                            @media screen and (max-width: 374px) {
                                padding: rem(16px) rem(0px) rem(16px) rem(20px) !important;
                            }
                            button {
                                padding-left: 0px;
                                padding-right: 10px;
                                width: 65px;
                                &::after {
                                    position: absolute;
                                    top: 16px;
                                    right: 50px;
                                }
                            }
                        }
                        &.opco-image {
                            padding: 0px !important;
                            .opco-image-name {
                                .image {
                                    margin-left: rem(20px);
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

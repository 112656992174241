<template>
    <div class="leaderboard__positions">
        <ul>
            <li v-if="users[1] && users[1].display_name">
                <span v-if="users[1].rank === 2">2<i>ND</i></span>
                <span v-else>{{ users[1].rank }}<i></i></span>
                <div class="leaderboard__positions--info">
                    <div
                        v-if="users[1] && users[1].display_name"
                        class="leaderboard__positions--name"
                    >
                        {{ users[1].display_name }}
                    </div>
                    <div class="leaderboard__positions--location">
                        {{ users[1].country }}
                    </div>
                    <p>
                        {{
                            users[1].level && users[1].level.name
                                ? users[1].level.name
                                : ''
                        }}
                    </p>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${users[1].score} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    users[1].my_submitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    users[1].my_resubmitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    users[1].my_approved_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    users[1].my_dismissed_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    users[1].my_planned_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    users[1].my_implemented_idea
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
            <li v-if="users[0] && users[0].display_name" class="winner">
                <span v-if="users[0].rank === 1">1<i>ST</i></span>
                <span v-else>{{ users[0].rank }}<i></i></span>
                <div class="leaderboard__positions--info">
                    <div class="leaderboard__positions--name">
                        {{ users[0].display_name }}
                    </div>
                    <div class="leaderboard__positions--location">
                        {{ users[0].country }}
                    </div>
                    <p>
                        {{
                            users[0].level && users[0].level.name
                                ? users[0].level.name
                                : ''
                        }}
                    </p>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${users[0].score} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    users[0].my_submitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    users[0].my_resubmitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    users[0].my_approved_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    users[0].my_dismissed_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    users[0].my_planned_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    users[0].my_implemented_idea
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
            <li v-if="users[2] && users[2].display_name">
                <span v-if="users[2].rank === 3">3<i>RD</i></span>
                <span v-else>{{ users[2].rank }}<i></i></span>
                <div class="leaderboard__positions--info">
                    <div class="leaderboard__positions--name">
                        {{ users[2].display_name }}
                    </div>
                    <div class="leaderboard__positions--location">
                        {{ users[2].country }}
                    </div>
                    <p>
                        {{
                            users[2].level && users[2].level.name
                                ? users[2].level.name
                                : ''
                        }}
                    </p>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${users[2].score} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    users[2].my_submitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    users[2].my_resubmitted_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    users[2].my_approved_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    users[2].my_dismissed_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    users[2].my_planned_idea
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    users[2].my_implemented_idea
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import imgBig from '@/assets/images/leaderboard/leaderboard-banner.png'
import imgLarge from '@/assets/images/leaderboard/leaderboard-banner.png'
import imgMedium from '@/assets/images/leaderboard/leaderboard-banner.png'

export default {
    props: {
        users: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.leaderboard {
    &__positions {
        position: relative;
        z-index: 2;
        &--info {
            padding-top: 25px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background: url(../../../../assets/images/leaderboard/stand2.svg)
                    left top no-repeat;
                background-size: cover;
                width: 100%;
                height: 154px;
                left: 0;
                top: 2px;
                z-index: -1;
            }
        }
        &--name {
            font-size: rem(19px);
            font-weight: 700;
        }
        &--location {
            font-size: rem(17px);
            font-weight: 700;
            color: #727272;
            margin: 5px 0;
        }
        p {
            color: #009b74;
            font-size: rem(18px);
            font-weight: 700;
            line-height: 1;
            margin-bottom: 4px;
        }
        /deep/ {
            .dropdown {
                .dropdown-toggle {
                    // font-size: rem(18px);
                    font-weight: 700;
                    padding-right: rem(22px);
                    text-transform: capitalize;
                    &:after {
                        background-image: url("data:image/svg+xml, %3Csvg fill='%23009B74' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='15.062' viewBox='0 0 26 15.062'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23009B74; fill-rule: evenodd; filter: url(%23filter); %7D %3C/style%3E%3Cfilter id='filter' x='667.969' y='632.969' width='26' height='15.062' filterUnits='userSpaceOnUse'%3E%3CfeFlood result='flood' flood-color='%23009B74'/%3E%3CfeComposite result='composite' operator='in' in2='SourceGraphic'/%3E%3CfeBlend result='blend' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath id='Forma_1' data-name='Forma 1' class='cls-1' d='M679.66,647.488l-11.178-11.36a1.871,1.871,0,0,1,0-2.616,1.8,1.8,0,0,1,2.575,0l9.891,10.051,9.89-10.051a1.8,1.8,0,0,1,2.574,0,1.871,1.871,0,0,1,0,2.616l-11.178,11.36A1.8,1.8,0,0,1,679.66,647.488Z' transform='translate(-667.969 -632.969)'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
        ul {
            list-style: none;
            padding-bottom: rem(70px);
            position: relative;
            z-index: 1;
            @include flex(start, center);
            @media screen and (min-width: 768px) {
                min-height: 395px;
            }
            li {
                text-align: center;
                position: relative;
                span {
                    width: rem(160px);
                    height: rem(160px);
                    line-height: 165px;
                    @include push--auto;
                    border-radius: 100%;
                    background: #009b74;
                    @include flex(center, center);
                    color: #fff;
                    font-weight: 800;
                    font-size: rem(50px);
                    position: relative;
                    top: 10px;
                    i {
                        font-size: rem(20px);
                        font-weight: 700;
                        font-style: normal;
                        position: relative;
                        top: -7px;
                        left: 3px;
                    }
                }
                &:first-child {
                    width: 250px;
                    .leaderboard__positions--info {
                        padding-top: 44px;
                    }
                }
                &:nth-child(2) {
                    width: 285px;
                    top: -30px;
                    z-index: 2;
                    > span {
                        background: #e4d700;
                        color: #000;
                    }
                    .leaderboard__positions {
                        &--location {
                            margin: 10px 0;
                            font-size: rem(20px);
                            font-weight: 800;
                        }

                        &--info {
                            padding-top: 54px;
                            @media screen and (max-width: 991px) {
                                padding-top: 40px;
                            }
                            p {
                                margin-bottom: 10px;
                                font-weight: 800;
                                font-size: rem(20px);
                            }
                            &:before {
                                background: url(../../../../assets/images/leaderboard/stand1.svg)
                                    left top no-repeat;
                                height: 204px;
                            }
                            /deep/.dropdown {
                                .dropdown-toggle {
                                    font-size: rem(20px);
                                }
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    width: 280px;
                    left: -30px;
                    bottom: -30px;
                    .leaderboard__positions {
                        &--location {
                            font-size: rem(16px);
                        }

                        &--info {
                            @media screen and (max-width: 767px) {
                                line-height: 16px;
                                padding-top: 18px !important;
                                min-height: 91px;
                            }
                            p {
                                font-size: rem(16px);
                            }
                            &:before {
                                background: url(../../../../assets/images/leaderboard/stand3.svg)
                                    left top no-repeat;
                                height: 123px;
                            }
                        }
                    }
                }
                // &.winner {
                //     &:before {
                //         content: '';
                //         position: absolute;
                //         background: url(../../../../assets/images/leaderboard/crown.svg)
                //             center top no-repeat;
                //         width: 70px;
                //         height: 56px;
                //         top: -43px;
                //         left: 0;
                //         right: 0;
                //         margin: auto;
                //     }
                // }
            }
        }
    }
    @media screen and (max-width: 991px) {
        &__positions {
            &--info {
                padding-top: 16px;
            }
            &--location {
                margin: 2px 0;
            }
            ul {
                li {
                    span {
                        line-height: 140px;
                    }
                    &:first-child {
                        width: 220px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 26px;
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        width: 225px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        width: 220px;
                        left: -13px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__positions {
            ul:not(.dropdown-menu) {
                flex-direction: column;
                padding-bottom: rem(100px);
                li {
                    @include push--auto;
                    &.winner {
                        &:before {
                            background-size: 100%;
                            width: 56px;
                            height: 45px;
                            top: -31px;
                        }
                    }
                    span {
                        width: rem(100px);
                        height: rem(100px);
                        font-size: rem(35px);
                        line-height: 89px;
                        i {
                            font-size: rem(16px);
                        }
                    }
                    &:first-child {
                        order: 2;
                        width: 200px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 18px;
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 102px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        .leaderboard {
                            &__positions {
                                &--location {
                                    margin: 6px 0;
                                }
                                &--info {
                                    padding-top: 26px;
                                    p {
                                        margin: 6px 0;
                                    }
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 132px;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        order: 3;
                        width: 200px;
                        left: 0;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 11px;
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 88px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ar {
    .leaderboard {
        &__positions {
            /deep/ {
                .dropdown {
                    .dropdown-toggle {
                        padding-left: rem(22px);
                    }
                }
            }
            ul {
                @include flex(start, center);
                @media screen and (min-width: 768px) {
                }
                li {
                    span {
                        direction: ltr;
                    }
                    &:first-child {
                        width: 285px;
                    }
                    &:nth-child(2) {
                        width: 281px;
                    }
                    &:nth-child(3) {
                        left: 0px;
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            &__positions {
                ul {
                    li {
                        &:first-child {
                            width: 220px;
                        }
                        &:nth-child(2) {
                            width: 225px;
                        }
                        &:last-child {
                            width: 220px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="filters">
        <form @submit.prevent="search">
            <div class="flex-wrapper">
                <div class="filters__search">
                    <input
                        v-model="searchKeyword"
                        type="text"
                        placeholder="Search.."
                        class="form-control"
                    />
                </div>
                <div class="filters__button">
                    <a class="btn btn-primary" @click="search">
                        Search
                    </a>
                </div>
                <div class="filters__dropdown">
                    <opco-filter @checkedOpco="checkedOpco" />
                </div>
                <div class="filters__dropdown">
                    <campaign-filter @checkedCampaign="checkedCampaign" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import CampaignFilter from '../../components/leaderboard/CampaignFilter.vue'
import OpcoFilter from '../../components/leaderboard/OpcoFilter.vue'
export default {
    components: {
        OpcoFilter,
        CampaignFilter,
    },
    data() {
        return {
            searchKeyword: '',
        }
    },
    methods: {
        search() {
            this.$emit('search', this.searchKeyword)
        },
        checkedOpco(value) {
            this.$emit('checkedOpco', value)
        },
        checkedCampaign(value) {
            this.$emit('checkedCampaign', value)
        },
    },
}
</script>

<style lang="scss" scoped>
.filters {
    // position: relative;
    z-index: 1;
    form {
        width: 100%;
    }
    .flex-wrapper {
        display: flex;
        align-items: center;
        > div {
            &:not(:first-child) {
                width: 180px;
                margin-left: rem(24px);
            }
        }
    }
    &__search {
        flex: 1;
        .form-control {
            height: 40px;
            background: #f9f9f9
                url(../../../../assets/images/leaderboard/search.svg);
            border: 1px solid #e9e9e9;
            box-shadow: none;
            font-size: 14px;
            font-weight: 400;
            background-repeat: no-repeat;
            background-position: left 16px center;
            padding-left: 50px;
            &::placeholder {
                color: #c4c4c6;
            }
        }
    }
    &__button {
        .btn {
            min-width: auto;
            background: transparent;
            color: var(--primary);
            border: 1px solid var(--primary);
            font-size: rem(17px);
            font-weight: 600;
            height: 40px;
            line-height: 40px;
            width: 100%;
            padding: 0 10px;
            &:hover {
                @media screen and (min-width: 1025px) {
                    background: var(--primary) !important;
                    color: #fff !important;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .flex-wrapper {
            flex-wrap: wrap;
            justify-content: center;
            > div {
                margin-bottom: rem(16px);
                &:not(:first-child) {
                    width: 150px;
                    margin-left: rem(16px);
                }
            }
        }
        &__search {
            width: 100%;
            flex: none;
            margin-bottom: rem(20px);
        }
    }
    @media screen and (max-width: 450px) {
        .flex-wrapper {
            flex-direction: column;
            > div {
                &:not(:first-child) {
                    width: 100%;
                    margin: 0 0 rem(16px) 0;
                }
            }
        }
    }
}
</style>

<template>
    <div class="leaderboard">
        <!-- Banner -->
        <base-innerBanner :banner-image="pageBanner"></base-innerBanner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->

                <div class="page__head">
                    <h1 class="page__head--title">Zainiac Leaderboard</h1>
                </div>

                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents">
                    <IndividualWinners
                        v-if="activeTab === 'individual' && !isMobileScreen()"
                        :users="
                            filters &&
                            ((filters.country && filters.country.length) ||
                                (filters.campaign && filters.campaign.length))
                                ? users
                                : winnersUsers
                        "
                    />
                    <OpcoWinners
                        v-if="activeTab === 'opco' && !isMobileScreen()"
                    />
                    <div
                        class="leaderboard__detail"
                        :class="{ 'details-top': isMobileScreen() }"
                    >
                        <div class="greyBg right"></div>
                        <div class="greyBg left"></div>
                        <ul class="leaderboard__links">
                            <li @click="activeTab = 'individual'">
                                <a
                                    href="javascript:void(0)"
                                    :class="{
                                        active: activeTab === 'individual',
                                    }"
                                >
                                    INDIVIDUAL
                                </a>
                            </li>
                            <li @click="activeTab = 'opco'">
                                <a
                                    href="javascript:void(0)"
                                    :class="{ active: activeTab === 'opco' }"
                                >
                                    OPCO
                                </a>
                            </li>
                        </ul>
                        <filters
                            v-if="activeTab === 'individual'"
                            @search="search"
                            @checkedOpco="checkedOpco"
                            @checkedCampaign="checkedCampaign"
                        />
                        <individual-data
                            v-if="activeTab === 'individual'"
                            :users="individualUsers"
                            :isMobileScreen="isMobileScreen()"
                        />
                        <opco-data
                            v-if="activeTab === 'opco'"
                            :isMobileScreen="isMobileScreen()"
                        />
                        <base-loader
                            v-if="loadding && activeTab === 'individual'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgBig from '@/assets/images/leaderboard/leaderboard-banner.png'
import imgLarge from '@/assets/images/leaderboard/leaderboard-banner.png'
import imgMedium from '@/assets/images/leaderboard/leaderboard-banner.png'
import { UsersHelper } from '@/common/crud-helpers/users'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import filters from '../components/leaderboard/Filters.vue'
import IndividualData from '../components/leaderboard/IndividualData.vue'
import IndividualWinners from '../components/leaderboard/IndividualWinners.vue'
import OpcoData from '../components/leaderboard/OpcoData.vue'
import OpcoWinners from '../components/leaderboard/OpcoWinners.vue'
export default {
    components: {
        filters,
        IndividualData,
        OpcoData,
        IndividualWinners,
        OpcoWinners,
    },
    data() {
        return {
            filters: {
                limit: 10,
                offset: 0,
                country: '',
                search: '',
            },
            loadding: true,
            activeTab: 'individual',
            users: [],
            individualUsers: [],
            winnersUsers: [],
            isWinnersSet: false,
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
        }
    },
    created() {
        this.getRankings()
        UsersHelper.getOpcoRanking()
        UsersHelper.getCampaigns()
    },
    computed: { ...mapGetters(['usersList']) },
    methods: {
        isMobileScreen() {
            return window.innerWidth < 991
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.loadding) {
                    let next = this.getNextOffset(this.usersList.next)
                    if (next && this.activeTab === 'individual') {
                        this.loadding = true
                        this.filters.offset = next
                        UsersHelper.list(this.filters)
                    }
                }
            }
        },
        search(value) {
            this.filters.search = value
            this.changeFilters()
        },
        checkedOpco(value) {
            if (value.length) {
                this.filters.country = value
                    .map(entry => entry.en_text)
                    .join(',')
            } else {
                delete this.filters.country
            }
            this.changeFilters()
        },
        checkedCampaign(value) {
            if (value.length) {
                this.filters.campaign = value
                    .map(entry => entry.value)
                    .join(',')
            } else {
                delete this.filters.campaign
            }
            this.changeFilters()
        },

        changeFilters() {
            if (this.filters.country && !this.filters.country.length) {
                delete this.filters.country
            }
            this.filters.limit = 10
            this.filters.offset = 0
            this.getRankings()
        },
        async getRankings() {
            this.loadding = true
            await UsersHelper.list(this.filters)
        },
        isOpcoSelected() {
            return !!(this.filters.country && this.filters.country.length)
        },
        isCampaignSelected() {
            return !!(this.filters.campaign && this.filters.campaign.length)
        },
        setIndividualUsers() {
            if (this.users && this.isMobileScreen()) {
                this.individualUsers = this.users
            } else if (
                this.users &&
                this.users.length &&
                !this.filters.search &&
                !this.isOpcoSelected() &&
                !this.isCampaignSelected()
            ) {
                this.individualUsers = [...this.users.slice(3)]
            } else if (
                this.users &&
                this.users.length &&
                this.filters.search &&
                !this.isOpcoSelected() &&
                !this.isCampaignSelected()
            ) {
                this.individualUsers = this.users
            } else if (
                this.users &&
                this.users.length &&
                (this.isOpcoSelected() || this.isCampaignSelected())
            ) {
                this.individualUsers = [...this.users.slice(3)]
            } else {
                this.individualUsers = []
            }
        },
    },
    mounted() {
        this.getNextData()
    },
    watch: {
        usersList() {
            if (this.filters.offset == 0) {
                this.users = []
            }
            let all = _.concat(this.users, this.usersList.results)
            this.users = cloneDeep(all)
            if (!this.isWinnersSet) {
                this.winnersUsers = this.users.slice(0, 3)
                this.isWinnersSet = true
            }
            this.setIndividualUsers()
            this.loadding = false
        },
        activeTab() {
            if (this.activeTab === 'individual') {
                this.filters.country = ''
                this.filters.search = ''
                this.changeFilters()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.sidenav-wrapper {
    margin-top: 0;
}
.page-contents {
    padding-top: rem(0px);
    position: relative;
    @media screen and (max-width: 991px) {
        padding-top: rem(85px);
    }
}
.leaderboard {
    overflow: hidden;
    .container {
        @media screen and (max-width: 991px) {
            max-width: 100%;
            padding: 0;
        }
    }
    &__detail {
        min-height: 400px;
        max-width: 1000px;
        margin: 80px auto auto auto;
        .greyBg {
            margin-top: 80px;
            --h: 400px;
            background: #e4d700;
            opacity: 0.14;
            &:before {
                background: #e4d700;
            }
            &.right {
                top: 65%;
            }
            &.left {
                z-index: -1;
                top: 120px;
            }
        }
        &.details-top {
            margin: 90px auto auto auto;
        }
    }
    .content-wrapper {
        position: relative;
        padding-top: 10rem;
        &:after {
            content: '';
            position: absolute;
            background: url(../../../assets/images/leaderboard/ribens-bg.svg)
                left top no-repeat;
            width: 100%;
            height: 500px;
            background-size: cover;
            top: 600px;
            bottom: 0;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0;
        }
    }
    /deep/.page-banner {
        --h: 848px;
        img {
            height: 100%;
            object-fit: cover;
        }
        &:before,
        &:after {
            display: none;
        }
    }
    .page__head {
        text-align: center;
        padding: 0;
        h1 {
            color: #e4d700;
        }
    }
    @media screen and (max-width: 991px) {
        .content-wrapper {
            &:after {
                top: 100px;
                bottom: auto;
            }
        }
    }
    @media screen and (max-width: 767px) {
        /deep/.page-banner {
            --h: 910px;
        }
        .page-contents {
            padding-top: rem(0px);
        }
    }
}
</style>

<template>
    <div class="leaderboard__positions">
        <ul
            v-if="
                opcoRanking && opcoRanking.result && opcoRanking.result.length
            "
        >
            <li
                v-if="
                    opcoRanking &&
                        opcoRanking.result[1] &&
                        opcoRanking.result[1].country
                "
            >
                <div class="leaderboard__positions--image">
                    <img
                        :src="imageName(opcoRanking.result[1].country)"
                        alt=""
                        class="img-fluid"
                    />
                </div>
                <div class="leaderboard__positions--info">
                    <div class="leaderboard__positions--name">
                        {{ opcoRanking.result[1].country }}
                    </div>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${opcoRanking.result[1].score_sum} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    opcoRanking.result[1].submitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    opcoRanking.result[1].resubmitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    opcoRanking.result[1].approved
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    opcoRanking.result[1].dismissed
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    opcoRanking.result[1].planned
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    opcoRanking.result[1].implemented
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
            <li
                v-if="
                    opcoRanking &&
                        opcoRanking.result[0] &&
                        opcoRanking.result[0].country
                "
                class="winner"
            >
                <div class="leaderboard__positions--image">
                    <img
                        :src="imageName(opcoRanking.result[0].country)"
                        alt=""
                        class="img-fluid"
                    />
                </div>
                <div class="leaderboard__positions--info">
                    <div class="leaderboard__positions--name">
                        {{ opcoRanking.result[0].country }}
                    </div>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${opcoRanking.result[0].score_sum} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    opcoRanking.result[0].submitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    opcoRanking.result[0].resubmitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    opcoRanking.result[0].approved
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    opcoRanking.result[0].dismissed
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    opcoRanking.result[0].planned
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    opcoRanking.result[0].implemented
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
            <li
                v-if="
                    opcoRanking &&
                        opcoRanking.result[2] &&
                        opcoRanking.result[2].country
                "
            >
                <div class="leaderboard__positions--image">
                    <img
                        :src="imageName(opcoRanking.result[2].country)"
                        alt=""
                        class="img-fluid"
                    />
                </div>
                <div class="leaderboard__positions--info">
                    <div class="leaderboard__positions--name">
                        {{ opcoRanking.result[2].country }}
                    </div>
                    <div>
                        <b-dropdown
                            id="dropdown-1"
                            :text="`${opcoRanking.result[2].score_sum} Points`"
                            class="m-md-2"
                        >
                            <b-dropdown-item
                                >Ideas Submitted:<span>{{
                                    opcoRanking.result[2].submitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Resubmitted:<span>{{
                                    opcoRanking.result[2].resubmitted
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Approved:<span>{{
                                    opcoRanking.result[2].approved
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Dismissed:<span>{{
                                    opcoRanking.result[2].dismissed
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Planned:<span>{{
                                    opcoRanking.result[2].planned
                                }}</span></b-dropdown-item
                            >
                            <b-dropdown-item
                                >Ideas Implemented:<span>{{
                                    opcoRanking.result[2].implemented
                                }}</span></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['opcoRanking']),
    },
    methods: {
        imageName(val) {
            if (val === 'ZainTech' || val === 'Zain Group') val = 'Kuwait'
            if (val === 'KSA') val = 'Saudi Arabia'
            if (val === 'Zain Cash') val = 'Jordan'
            const imageName = val.toLowerCase().replace(' ', '-')
            const image = require(`../../../../assets/images/leaderboard/country-flags/${imageName}.png`)
            return image
        },
    },
}
</script>

<style lang="scss" scoped>
.leaderboard {
    &__positions {
        position: relative;
        z-index: 2;
        &--image {
            width: rem(160px);
            height: rem(160px);
            border-radius: 100%;
            overflow: hidden;
            @include push--auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--info {
            padding-top: 53px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background: url(../../../../assets/images/leaderboard/stand2.svg)
                    left top no-repeat;
                background-size: cover;
                width: 100%;
                height: 154px;
                left: 0;
                top: 2px;
                z-index: -1;
            }
        }
        &--name {
            font-size: rem(19px);
            font-weight: 700;
            margin-bottom: 10px;
        }
        /deep/ {
            .dropdown {
                .dropdown-toggle {
                    // font-size: rem(18px);
                    font-weight: 700;
                    padding-right: rem(22px);
                    text-transform: capitalize;
                    &:after {
                        background-image: url("data:image/svg+xml, %3Csvg fill='%23009B74' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='15.062' viewBox='0 0 26 15.062'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23009B74; fill-rule: evenodd; filter: url(%23filter); %7D %3C/style%3E%3Cfilter id='filter' x='667.969' y='632.969' width='26' height='15.062' filterUnits='userSpaceOnUse'%3E%3CfeFlood result='flood' flood-color='%23009B74'/%3E%3CfeComposite result='composite' operator='in' in2='SourceGraphic'/%3E%3CfeBlend result='blend' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath id='Forma_1' data-name='Forma 1' class='cls-1' d='M679.66,647.488l-11.178-11.36a1.871,1.871,0,0,1,0-2.616,1.8,1.8,0,0,1,2.575,0l9.891,10.051,9.89-10.051a1.8,1.8,0,0,1,2.574,0,1.871,1.871,0,0,1,0,2.616l-11.178,11.36A1.8,1.8,0,0,1,679.66,647.488Z' transform='translate(-667.969 -632.969)'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
        ul {
            @include flex(start, center);
            list-style: none;
            padding-bottom: rem(70px);
            position: relative;
            z-index: 1;
            min-height: 395px;
            li {
                text-align: center;
                position: relative;
                span {
                    width: rem(160px);
                    height: rem(160px);
                    @include push--auto;
                    border-radius: 100%;
                    background: #009b74;
                    @include flex(center, center);
                    color: #fff;
                    font-weight: 800;
                    font-size: rem(50px);
                    line-height: 1;
                    position: relative;
                    top: 10px;
                    i {
                        font-size: rem(20px);
                        font-weight: 700;
                        font-style: normal;
                        position: relative;
                        top: -7px;
                        left: 3px;
                    }
                }
                &:first-child {
                    width: 250px;
                    .leaderboard__positions--info {
                        padding-top: 65px;
                    }
                }
                &:nth-child(2) {
                    width: 285px;
                    top: -30px;
                    z-index: 2;
                    > span {
                        background: #e4d700;
                        color: #000;
                    }
                    .leaderboard__positions {
                        &--location {
                            margin: 10px 0;
                            font-size: rem(20px);
                            font-weight: 800;
                        }

                        &--info {
                            padding-top: 95px;
                            p {
                                margin-bottom: 10px;
                                font-weight: 800;
                                font-size: rem(20px);
                            }
                            &:before {
                                background: url(../../../../assets/images/leaderboard/stand1.svg)
                                    left top no-repeat;
                                height: 204px;
                            }
                            /deep/.dropdown {
                                .dropdown-toggle {
                                    font-size: rem(20px);
                                }
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    width: 280px;
                    left: -30px;
                    bottom: -30px;
                    .leaderboard__positions {
                        &--location {
                            font-size: rem(16px);
                        }

                        &--info {
                            p {
                                font-size: rem(16px);
                            }
                            &:before {
                                background: url(../../../../assets/images/leaderboard/stand3.svg)
                                    left top no-repeat;
                                height: 123px;
                            }
                        }
                    }
                }
                // &.winner {
                //     &:before {
                //         content: '';
                //         position: absolute;
                //         background: url(../../../../assets/images/leaderboard/crown.svg)
                //             center top no-repeat;
                //         width: 70px;
                //         height: 56px;
                //         top: -55px;
                //         left: 0;
                //         right: 0;
                //         margin: auto;
                //     }
                // }
            }
        }
    }
    @media screen and (max-width: 991px) {
        &__positions {
            &--info {
                padding-top: 28px;
            }
            &--location {
                margin: 2px 0;
            }
            ul {
                li {
                    &:first-child {
                        width: 220px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 36px;
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        width: 225px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 72px;
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        width: 220px;
                        left: -13px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    &:before {
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__positions {
            margin-top: rem(120px);
            &--image {
                width: rem(100px);
                height: rem(100px);
            }
            > ul:not(.dropdown-menu) {
                flex-direction: column;
                padding-bottom: rem(100px);
                li {
                    @include push--auto;
                    &.winner {
                        &:before {
                            background-size: 100%;
                            width: 56px;
                            height: 45px;
                            top: -43px;
                        }
                    }
                    &:first-child {
                        order: 2;
                        width: 200px;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 25px;
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 85px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        .leaderboard {
                            &__positions {
                                &--location {
                                    margin: 6px 0;
                                }
                                &--info {
                                    padding-top: 30px;
                                    p {
                                        margin: 6px 0;
                                    }
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 107px;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        order: 3;
                        width: 200px;
                        left: 0;
                        .leaderboard {
                            &__positions {
                                &--info {
                                    padding-top: 17px;
                                    &:before {
                                        background-size: 100% 100%;
                                        height: 70px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ar {
    .leaderboard {
        &__positions {
            /deep/ {
                .dropdown {
                    .dropdown-toggle {
                        padding-left: rem(22px);
                    }
                }
            }
            ul {
                @include flex(start, center);
                @media screen and (min-width: 768px) {
                }
                li {
                    &:first-child {
                        width: 285px;
                    }
                    &:nth-child(2) {
                        width: 281px;
                    }
                    &:nth-child(3) {
                        left: 0px;
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            &__positions {
                ul {
                    li {
                        &:first-child {
                            width: 220px;
                        }
                        &:nth-child(2) {
                            width: 225px;
                        }
                        &:last-child {
                            width: 220px;
                        }
                    }
                }
            }
        }
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leaderboard__positions"},[(
            _vm.opcoRanking && _vm.opcoRanking.result && _vm.opcoRanking.result.length
        )?_c('ul',[(
                _vm.opcoRanking &&
                    _vm.opcoRanking.result[1] &&
                    _vm.opcoRanking.result[1].country
            )?_c('li',[_c('div',{staticClass:"leaderboard__positions--image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.imageName(_vm.opcoRanking.result[1].country),"alt":""}})]),_c('div',{staticClass:"leaderboard__positions--info"},[_c('div',{staticClass:"leaderboard__positions--name"},[_vm._v(" "+_vm._s(_vm.opcoRanking.result[1].country)+" ")]),_c('div',[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":((_vm.opcoRanking.result[1].score_sum) + " Points")}},[_c('b-dropdown-item',[_vm._v("Ideas Submitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].submitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Resubmitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].resubmitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Approved:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].approved))])]),_c('b-dropdown-item',[_vm._v("Ideas Dismissed:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].dismissed))])]),_c('b-dropdown-item',[_vm._v("Ideas Planned:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].planned))])]),_c('b-dropdown-item',[_vm._v("Ideas Implemented:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[1].implemented))])])],1)],1)])]):_vm._e(),(
                _vm.opcoRanking &&
                    _vm.opcoRanking.result[0] &&
                    _vm.opcoRanking.result[0].country
            )?_c('li',{staticClass:"winner"},[_c('div',{staticClass:"leaderboard__positions--image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.imageName(_vm.opcoRanking.result[0].country),"alt":""}})]),_c('div',{staticClass:"leaderboard__positions--info"},[_c('div',{staticClass:"leaderboard__positions--name"},[_vm._v(" "+_vm._s(_vm.opcoRanking.result[0].country)+" ")]),_c('div',[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":((_vm.opcoRanking.result[0].score_sum) + " Points")}},[_c('b-dropdown-item',[_vm._v("Ideas Submitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].submitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Resubmitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].resubmitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Approved:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].approved))])]),_c('b-dropdown-item',[_vm._v("Ideas Dismissed:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].dismissed))])]),_c('b-dropdown-item',[_vm._v("Ideas Planned:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].planned))])]),_c('b-dropdown-item',[_vm._v("Ideas Implemented:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[0].implemented))])])],1)],1)])]):_vm._e(),(
                _vm.opcoRanking &&
                    _vm.opcoRanking.result[2] &&
                    _vm.opcoRanking.result[2].country
            )?_c('li',[_c('div',{staticClass:"leaderboard__positions--image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.imageName(_vm.opcoRanking.result[2].country),"alt":""}})]),_c('div',{staticClass:"leaderboard__positions--info"},[_c('div',{staticClass:"leaderboard__positions--name"},[_vm._v(" "+_vm._s(_vm.opcoRanking.result[2].country)+" ")]),_c('div',[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":((_vm.opcoRanking.result[2].score_sum) + " Points")}},[_c('b-dropdown-item',[_vm._v("Ideas Submitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].submitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Resubmitted:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].resubmitted))])]),_c('b-dropdown-item',[_vm._v("Ideas Approved:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].approved))])]),_c('b-dropdown-item',[_vm._v("Ideas Dismissed:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].dismissed))])]),_c('b-dropdown-item',[_vm._v("Ideas Planned:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].planned))])]),_c('b-dropdown-item',[_vm._v("Ideas Implemented:"),_c('span',[_vm._v(_vm._s(_vm.opcoRanking.result[2].implemented))])])],1)],1)])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="filters__dropdown">
        <div
            class="checkbox-select"
            :class="{ isActive: showDropdown }"
            @click="showDropdown = !showDropdown"
        >
            <div class="checkbox-select__trigger">
                <img
                    src="@/assets/images/leaderboard/filter-icon.svg"
                    alt="filter-icon"
                />
                <span class="checkbox-select__title">Campaign Filter</span>
            </div>
            <div class="checkbox-select__dropdown">
                <ul
                    class="checkbox-select__filters-wrapp"
                    data-simplebar-auto-hide="false"
                >
                    <li
                        v-for="(filter, index) in enabledCampaigns"
                        :key="`campaign-${index}`"
                    >
                        <div
                            class="checkbox-select__check-wrapp"
                            v-if="filter.enabled"
                        >
                            <input
                                v-model="checkedCampaign"
                                :id="`campaign-value-${index}`"
                                :value="filter"
                                class="conditions-check"
                                type="checkbox"
                            />
                            <label :for="`campaign-value-${index}`">{{
                                filter.text
                            }}</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            checkedCampaign: [],
            showDropdown: false,
            enabledCampaigns: [],
        }
    },
    computed: { ...mapGetters(['campaignFilters']) },
    created() {
        if (
            this.campaignFilters &&
            this.campaignFilters.results &&
            this.campaignFilters.results.length
        )
            this.setDynamicCampaigns()
    },
    methods: {
        setDynamicCampaigns() {
            this.enabledCampaigns = []
            this.enabledCampaigns = this.campaignFilters.results
                .filter(campaign => campaign.active === true)
                .map(campaign => {
                    const value = campaign.title
                    const text = campaign[`title_${this.$i18n.locale}`]

                    return {
                        value,
                        text,
                        enabled: campaign.active,
                    }
                })
            this.enabledCampaigns.sort((a, b) => {
                const textA = a.value.toUpperCase()
                const textB = b.value.toUpperCase()
                if (textA < textB) {
                    return -1
                } else if (textA > textB) {
                    return 1
                } else {
                    return 0
                }
            })
        },
    },
    watch: {
        checkedCampaign() {
            this.$emit('checkedCampaign', this.checkedCampaign)
        },
        campaignFilters() {
            this.setDynamicCampaigns()
        },
    },
}
</script>

<style lang="scss" scoped>
.checkbox-select {
    position: relative;
    width: 100%;
    min-height: auto !important;
    &__trigger {
        background: var(--primary);
        color: #fff;
        font-size: rem(17px);
        font-weight: 600;
        position: relative;
        z-index: 1;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 10px;
        transition: all 0.4s ease;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include flex(center, center);

        &:hover {
            opacity: 0.8;
        }
        img {
            width: 28px;
            transition: all 0.4s ease;
            @media only screen and (max-width: 600px) {
                width: 22px;
            }
        }
    }
    &__dropdown {
        opacity: 0;
        visibility: hidden;
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: rem(18px);
        border: 1px solid #e0e0e0;
        margin-top: rem(50px);
    }
    &__col {
        display: flex;
        font-size: 12px;
        padding: 0 25px;
        justify-content: space-between;
        text-transform: uppercase;
        @media only screen and (max-width: 600px) {
            padding: 0 15px;
        }
    }
    &__filters-wrapp {
        overflow-y: auto;
    }
    &__check-wrapp {
        position: relative;
        input[type='checkbox'] {
            display: none;

            & + label {
                position: relative;
                cursor: pointer;
                font-size: rem(16px);
                color: #000;
                font-weight: 600;
                line-height: 22px;
                padding-left: 30px;
                display: inline-block;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition: padding 0.25s ease;
                &:after {
                    border: solid 1px #e0e0e0;
                    content: '';
                    width: 17px;
                    height: 17px;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
                &:before {
                    width: 15px;
                    height: 15px;
                    content: '';
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background-color: var(--primary);
                    opacity: 0;
                    will-change: transform;
                    transform: scale(0.5);
                    transition: all 0.2s ease;
                }
                &:hover {
                    padding-left: 32px;
                }
            }
            &:checked {
                & + label {
                    &:before {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    &.isActive {
        .checkbox-select {
            &__dropdown {
                opacity: 1;
                visibility: visible;
                z-index: 1000;
            }
        }
    }
}

@keyframes load {
    0% {
        left: -200px;
        width: 20%;
    }
    50% {
        width: 40%;
    }
    70% {
        width: 60%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    100% {
        left: 100%;
    }
}
.ar {
    .checkbox-select {
        &__check-wrapp {
            input[type='checkbox'] {
                & + label {
                    padding-left: 0px;
                    padding-right: 30px;

                    &:after {
                        right: 0;
                    }
                    &:before {
                        right: 1px;
                    }
                }
            }
        }
    }
}
</style>
